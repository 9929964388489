import '@vidstack/react/player/styles/default/theme.css';
import '@vidstack/react/player/styles/default/layouts/video.css';
import Player from "./player";
import {useEffect, useState} from "react";

function App() {
    const [video, setVideo] = useState(null)
    const [name, setName] = useState('')
    useEffect(() => {
        const location = window.location;
        let params = new URLSearchParams(location.search)
        if (params.has('video')) {
            setVideo(params.get('video'))
        }
        if (params.has('name')) {
            setName(params.get('name'))
        }
        if (params.has('download')) {
            window.location.replace(params.get('download'))
        }
    },[])
  return (
    <div className="App">
        {video ?
            <Player video={video} name={name}/> :
            <p>no video</p>
        }
    </div>
  );
}

export default App;
