import {MediaPlayer, MediaProvider} from "@vidstack/react";
import {
    DefaultVideoLayout,
    defaultLayoutIcons
} from '@vidstack/react/player/layouts/default';


function Player({video, name}) {
    return (
        <MediaPlayer title={name} src={video}>
            <MediaProvider/>
            <DefaultVideoLayout icons={defaultLayoutIcons}/>
        </MediaPlayer>
    )
}

export default Player;